<template>
  <div class="join_comp">
    <div v-if="!logined&&!join_succeed">
      
    <table 
      width="100%"
      border="0"
      cellpadding="0"
      cellspacing="1"
      class="formBox"
      v-if="loaded"
    >
      <tbody>
        <tr>
          <th height="45" colspan="2" class="formListTh">
            ご依頼主(ご注文者様)情報
          </th>
        </tr>

        <tr class="table1">
          <td valign="top" class="formListTd" style="width: 11em">
            ご注文者様名<span class="nesry">必須</span>
          </td>
          <td class="formListTdw">
            <span class="em2">姓</span>
            <input
              v-model="fields.sei.value"
              class="fm_100"
              name="user_name1"
              type="text"
              id="user_name1"
              value=""
            />
            
          <inlineError name="sei" :errors="errors" />

            <span class="em2">名</span>
            <input
              v-model="fields.mei.value"
              class="fm_100"
              name="user_name2"
              type="text"
              id="user_name2"
              value=""
            />

          <inlineError name="mei" :errors="errors" />
            <div class="cartinfosample">
              例：<span>黒須</span> ／ <span>三太</span>
            </div>
          </td>
        </tr>
        <tr class="table1">
          <td class="formListTd">
            ご注文者様名ふりがな<span class="nesry">必須</span>
          </td>
          <td class="formListTdw">
            <div class="cartselinfo">
              ※ひらがな、またはカタカナで入力してください。
            </div>
            <span class="em2">せい</span>
            <input
              class="fm_100"
              v-model="fields.sei_kana.value"
              name="user_name_kana1"
              type="text"
              id="user_name_kana1"
              value=""
            />
          <inlineError name="sei_kana" :errors="errors" />
            <span class="em2">めい</span>
            <input
              class="fm_100"
              v-model="fields.mei_kana.value"
              name="user_name_kana2"
              type="text"
              id="user_name_kana2"
              value=""
            />
          <inlineError name="mei_kana" :errors="errors" />

            <div class="cartinfosample">
              例：<span>くろす</span> ／ <span>さんた</span>
            </div>
          </td>
        </tr>

        <tr class="table1">
          <td class="formListTd"><span class="tdmenu">ご注文者様</span>住所</td>
          <td class="formListTdw">
            <table border="0" class="formBox">
              <tbody>
                <tr>
                  <td width="29%" class="formListTd">
                    郵便番号<span class="nesry">必須</span>
                  </td>
                  <td width="71%" class="formListTdw">
                    <div class="cartselinfo">
                      ※住所が未入力の場合、正しく郵便番号を入力すると自動的に住所を入力します。
                    </div>
                    <input
                      v-model="fields.zip.value"
                      class="fm_150 zipsearch"
                      name="user_zip"
                      type="text"
                      id="user_zip"
                      value=""
                    />
          <inlineError name="zip" :errors="errors" />

                    <div class="cartinfosample">例：<span>123-0001</span></div>
                  </td>
                </tr>
                <tr>
                  <td class="formListTd">
                    都道府県<span class="nesry">必須</span>
                  </td>
                  <td class="formListTdw">
                    <select
                      name="user_ken"
                      id="user_ken"
                      class="zipsearch_ken"
                      v-model="fields.state.value"
                    >
                      <option value="">都道府県</option>
                      <option value="北海道">北海道</option>
                      <option value="青森県">青森県</option>
                      <option value="岩手県">岩手県</option>
                      <option value="宮城県">宮城県</option>
                      <option value="秋田県">秋田県</option>
                      <option value="山形県">山形県</option>
                      <option value="福島県">福島県</option>
                      <option value="茨城県">茨城県</option>
                      <option value="栃木県">栃木県</option>
                      <option value="群馬県">群馬県</option>
                      <option value="埼玉県">埼玉県</option>
                      <option value="千葉県">千葉県</option>
                      <option value="東京都">東京都</option>
                      <option value="神奈川県">神奈川県</option>
                      <option value="新潟県">新潟県</option>
                      <option value="富山県">富山県</option>
                      <option value="石川県">石川県</option>
                      <option value="福井県">福井県</option>
                      <option value="山梨県">山梨県</option>
                      <option value="長野県">長野県</option>
                      <option value="岐阜県">岐阜県</option>
                      <option value="静岡県">静岡県</option>
                      <option value="愛知県">愛知県</option>
                      <option value="三重県">三重県</option>
                      <option value="滋賀県">滋賀県</option>
                      <option value="京都府">京都府</option>
                      <option value="大阪府">大阪府</option>
                      <option value="兵庫県">兵庫県</option>
                      <option value="奈良県">奈良県</option>
                      <option value="和歌山県">和歌山県</option>
                      <option value="鳥取県">鳥取県</option>
                      <option value="島根県">島根県</option>
                      <option value="岡山県">岡山県</option>
                      <option value="広島県">広島県</option>
                      <option value="山口県">山口県</option>
                      <option value="徳島県">徳島県</option>
                      <option value="香川県">香川県</option>
                      <option value="愛媛県">愛媛県</option>
                      <option value="高知県">高知県</option>
                      <option value="福岡県">福岡県</option>
                      <option value="佐賀県">佐賀県</option>
                      <option value="長崎県">長崎県</option>
                      <option value="熊本県">熊本県</option>
                      <option value="大分県">大分県</option>
                      <option value="宮崎県">宮崎県</option>
                      <option value="鹿児島県">鹿児島県</option>
                      <option value="沖縄県">沖縄県</option>
                    </select>
          <inlineError name="state" :errors="errors" />

                    <div class="cartinfosample">例：<span>栃木県</span></div>
                  </td>
                </tr>
                <tr>
                  <td class="formListTd">
                    市区町村<span class="nesry">必須</span>
                  </td>
                  <td class="formListTdw">
                    <input
                      v-model="fields.city.value"
                      class="fm_150 zipsearch_shi"
                      id="user_shi"
                      name="user_shi"
                      type="text"
                      value=""
                    />
          <inlineError name="city" :errors="errors" />

                    <div class="cartinfosample">
                      例：<span>那須郡那須町</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="formListTd">
                    地域・番地<span class="nesry">必須</span>
                  </td>
                  <td class="formListTdw">
                    <input
                      v-model="fields.street.value"
                      class="fm_250 zipsearch_machi"
                      id="user_addr01"
                      name="user_addr01"
                      type="text"
                      value=""
                    />
          <inlineError name="street" :errors="errors" />

                    <div class="cartinfosample">
                      例：<span>高久乙3584-4</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="formListTd">アパート・マンション・建物名</td>
                  <td class="formListTdw">
                    <div class="cartselinfo">
                      ※建物名、号室まで正確に入力してください
                    </div>
                    <input
                      v-model="fields.building.value"
                      class="fm_250"
                      id="user_addr02"
                      name="user_addr02"
                      type="text"
                      value=""
                    />
          <inlineError name="building" :errors="errors" />

                    <div class="cartinfosample">
                      例：<span>クリスマスマンションＡ棟５００号室</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="formListTd">様方・会社名</td>
                  <td class="formListTdw">
                    <input
                      v-model="fields.other.value"
                      class="fm_250"
                      id="user_addr03"
                      name="user_addr03"
                      type="text"
                      value=""
                    />
          <inlineError name="other" :errors="errors" />

                    <div class="cartinfosample">例：<span>黒須様方</span></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr class="table1">
          <td class="formListTd">
            ご注文者様電話番号<span class="nesry">必須</span>
          </td>
          <td class="formListTdw">
            <div class="cartselinfo">
              ※数字とハイフンで入力してください。<br />※市外局番から入力してください
            </div>
            <input
              class="fm_150"
              name="user_tel"
              type="text"
              v-model="fields.tel.value"
            />
          <inlineError name="tel" :errors="errors" />

            <div class="cartinfosample">例：<span>0287-99-9999</span></div>
          </td>
        </tr>
        <!--
        <tr class="table1">
          <td class="formListTd">
            <span class="tdmenu">ご注文者様</span>携帯電話番号
          </td>
          <td class="formListTdw">
            <div class="cartselinfo">
              ※携帯番号しかお持ちでない方は、ご注文者様電話番号欄へ入力してください。<br />※数字とハイフンで入力してください。
            </div>
            <input class="fm_150" name="user_mtel" type="text" value="" />

            <div class="cartinfosample">例：<span>080-9999-9999</span></div>
          </td>
        </tr>-->
        <tr class="table1">
          <td height="74" class="formListTd">
            ご注文者様メールアドレス<span class="nesry">必須</span>
          </td>
          <td class="formListTdw">
            <div class="cartselinfo">
              ※間違いのないよう、正確に入力してください。<br />
              ※携帯アドレスをご使用の場合、@santaclaus-embassy.comからのメールを受信できるように設定してください。
            </div>
            <input
              class="fm_150"
              name="user_email"
              type="text"
              v-model="fields.email.value"
            />
          <inlineError name="email" :errors="errors" />

            <div class="cartinfosample">
              例：<span>mail@santaclaus-embassy.com</span>
            </div>
          </td>
        </tr>
        <tr class="table1">
          <td height="74" class="formListTd">
            ご注文者様メールアドレス<br />
            再入力<span class="nesry">必須</span>
          </td>
          <td class="formListTdw">
            <div class="cartselinfo">
              ※コピーせずにもう一度入力してください。
            </div>

            <div class="cartinfosample">
              ▼ご確認のためもう一度メールアドレスを入力してください。
            </div>

            <input onpaste="return false" oncontextmenu="return false" class="fm_150" name="user_email2" type="text" 
              v-model="fields.email_c.value" />
          <inlineError name="email_c" :errors="errors" />

            <div class="cartinfosample">
              例：<span>mail@santaclaus-embassy.com</span>
            </div>
          </td>
        </tr>
        <!--
        <tr class="table1 memberbox">
          <td valign="top" class="formListTd" style="width: 11em">会員登録</td>
          <td class="formListTdw">
            <input
              type="checkbox"
              name="user_submit"
              value="on"
              checked=""
            />
            会員登録をして、次回から住所などを自動入力する

            <div class="cartinfosample">
              今回のご注文完了と同時に会員登録をして、次回からの入力を省略することができます。毎年忘れずにお申し込みできるようにお知らせも配信されます。
            </div>
          </td>
        </tr>
        -->

        <tr class="table1">
          <td valign="top" class="formListTd" style="width: 11em">
            希望するパスワード
          </td>
          <td class="formListTdw">
            <input
              type="password"
              name="user_pass"
              v-model="fields.userpass.value"
            />
          <inlineError name="userpass" :errors="errors" />
            <div class="cartinfosample">
              半角英数字6～50文字でお願いします。（記号可）
            </div>
            <input type="password" name="user_pass_c" 
              v-model="fields.userpass_c.value"
            />
          <inlineError name="userpass_c" :errors="errors" />
            <div class="cartinfosample">
              確認のためにもう一度入力してください。
            </div>
          </td>
        </tr>
        <tr class="table1 mailmagbox" style="display: none">
          <td class="formListTd">メルマガ登録</td>
          <td class="formListTdw">
            <!--<div class="cartselinfo">※サンタクロース大使館から情報メールをお送りしてもよろしい場合はチェックを入れてください</div>-->
            <input
              name="user_mailmaga"
              id="user_mailmaga"
              type="checkbox" 
              value="on"
              v-model="fields.mailmag.value"
              checked="checked"
            />
            <label for="user_mailmaga"
              >メルマガ登録する
              (おすすめ！毎年忘れずにお申し込みできるようにお知らせ)</label
            ><br />
            <!--<div class="cartinfosample">※年に数回程度の発行です。新しい商品などをお知らせしますので、ぜひご登録ください。</div>-->
          </td>
        </tr>

        <tr class="table1">
          <td class="formListTd">規約への同意<span class="nesry">必須</span></td>
          <td class="formListTdw">
            <div class="cartselinfo">
              ※同意を頂けましたらチェックを入れてください。
            </div>
           
            <input name="agreement" type="checkbox" value="on" v-model="fields.policy.value" />
             <router-link @click.native="scrollToTop" to="/informations/terms">ご利用規約</router-link> 
           ,
           <router-link @click.native="scrollToTop" to="/informations/privacy">プライバシーポリシー</router-link>
に同意する
          <inlineError name="policy" :errors="errors" />
          </td>
        </tr>
      </tbody>
    </table>

<button @click="submit">この内容で登録</button>
</div>
<div v-else>
  ありがとうございます。<br>
会員登録が完了しました。

</div>

    <dialogalert
      v-model="visible_error"
      :title="error_title"
      :info="error_info"
      button_ok="OK"
    />
    
<fullloader :loading="fullloading" />


  </div>
</template>

<script>
import inlineError from './inlinError.vue';
//import { mapState} from 'vuex'
export default {
  components:{
    inlineError
  },
  //新規会員の機能はコンポーネント化してポップアップで会員登録できるようにする
  data: function () {
    return {
        join_succeed:false,
      fields: {
        email:{value:""},
        email_c:{value:""},
        policy:{value:false},
        userpass:{value:""},
        userpass_c:{value:""},
        mailmag:{value:""},
      },
      errors:[]
    };
  },
  computed:{
     /*...mapState([
      'token'
    ]),*/
    fields_available:function(){
      return Object.keys(this.fields).length;
    }
  },
  methods:{
    getFields_filter:function(tags){
      var gotfields=this.record.fields;
      for(var i=0;i<tags.length;i++){console.log(gotfields);
        for(var key in gotfields) {
          if(gotfields[key].tag && gotfields[key].tag.indexOf(tags[i])!==-1){
            //console.log("親から継承",key,gotfields[key]);
            this.$set(this.fields,key,gotfields[key]);
          }
        }
      }
    },
    setFields_filter:function(tags){
      var gotfields=this.getSession("myuser_fields",true);
      for(var i=0;i<tags.length;i++){
        for(var key in gotfields) {
          if(gotfields[key].tag && gotfields[key].tag.indexOf(tags[i])!==-1){
            //console.log("親から継承",key,gotfields[key]);
            gotfields[key].value=this.fields[key].value;
            this.setSession("myuser_fields",this.fields,true)
          }
        }
      }
    },
    deleteFields_filter:function(tags){
      var gotfields=this.getSession("myuser_fields",true);
      for(var i=0;i<tags.length;i++){
        for(var key in gotfields) {
          if(gotfields[key].tag.indexOf(tags[i])!==-1){
            //console.log("親から継承",key,gotfields[key]);
            gotfields[key].value="";
            this.setSession("myuser_fields",this.fields,true)
          }
        }
      }
    },
    submit:function(){
      this.fullloading=true;
        var data = { fields: this.fields};
        this.fetch("myuser", data).then(data => {
      this.fullloading=false;
            if (data.errormsg) {
                this.errormsg = data.errormsg;
                this.errors = data.errors;

                this.visible_error = true;
                this.error_info = this.errormsg+"<br>";
                for (let i = 0; i < data.errors.length; i++) {
                  this.error_info+="・ "+data.errors[i].msg+"<br>";
                }

            } else {
                this.errors =[];
                this.errormsg = "";
                this.setSession("token",data.token,true);
                this.setSession('logined', true);
                this.join_succeed=true;
                this.login();
            }
        })
    }
  },
  created:function(){
    this.loaded_countmax=1;
    for(var i in this.fields){
      if(typeof this.record.fields[i]=="undefined")this.record.fields[i]=this.fields[i];
    }
    this.fields=this.record.fields;
     //this.fields=
     //this.getFields_filter(["join"]);
     console.log(this.fields)
      this.loaded_countup();
  },
  watch: {
    fields: {
      handler: function () {
        console.log("changed!", status);
        //console.log("オプションの値が変更されたのでフィールドにセット");
        //直接値を書きかえるのではなく、コンポーネントにあらかじめ用意した値書き換え用のcomputeを使用してセットする。
        //this.$parent.value=JSON.stringify(this.values);
        //this.record.fields=this.fields;
        //this.$set(this.record,"myuser_fields",this.fields);
				//this.setSession("record",this.record,true);
        //this.setFields_filter(["join"]);
        //console.log(this.record)
      },
      deep: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
table.formBox {
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
  th,
  td {
    padding: 0.4em;
    border-bottom: solid 1px #ccc;
  }
  > tbody {
    > tr {
      > td:nth-child(1) {
        background: rgb(241, 241, 241);
      }
      border-bottom: solid 1px #ccc;
    }
  }
  .cartinfosample {
    font-size: 75%;
    margin-top: 5px;
    line-height: 1.2;
  }
  .cartselinfo {
    background-color: #fff2c8;
    padding: 10px;
    float: right;
    width: 190px;
    font-size: 75%;
    color: #282828;
    line-height: 1.5;
  }
  .cartinfosample span {
    font-weight: bold;
    color: #66b158;
  }
  element.style {
  }
  .nesry {
    font-size: 70%;
    float: right;
    padding-top: 2px;
    padding-right: 7px;
    padding-bottom: 2px;
    padding-left: 7px;
    color: #f51212;
    margin-right: 5px;
    border: 1px solid #f51212;
  }
}
</style>
