<template>
  <div>
   <h1>ログイン・新規会員登録</h1>
   <hr>
   <div class="pageinfo">
    既に会員済みの方は、以下からログインしてください。
   </div>
<login to="/mypage/" v-if="!logined" />
<hr>

   <div class="pageinfo">
    新しく会員登録をなさる方は、こちらから必要事項をご入力ください。
   </div>
<join/>
<div v-if="logined" class="bn_tomypage">
  
          <router-link @click.native="scrollToTop" to="/mypage/">
          マイページへ
          </router-link>
          </div>


  </div>
</template>

<script>
import join from '@/components/join';
import login from '@/components/login';
export default  {
    components:{
        join,login
    },
    created: function() { this.$parent.middle = 2; },
    computed:{
      /*token:function(){
        return this.$store.state.token;
      }*/
    }
}
</script>

<style scoped>
h1{
  margin-top: 1em;
}
.bn_tomypage{
  padding: 3em 0;
}
.pageinfo{
  font-size: .9em;
  padding-top: 1.5em;
  padding-bottom: .5em;
  color: rgb(84, 77, 87);
}
</style>